section.section.is-flex.p-4
	div.box.p-0.is-flex-grow-1.is-gapless.is-shadowless.timetable-outer-radius
		div.is-flex.is-justify-content-space-between.px-4.py-3
			div.is-flex.is-flex-grow-1.is-unselectable
				img [
					src="./assets/nfc_FILL0_wght400_GRAD0_opsz24.svg"
					alt="TimeTable App Logo"
				]
				p.px-2.has-text-centered.has-text-weight-bold -- Time Control
			<!-- div.is-flex.is-flex-grow-1.is-justify-content-center
				p.px-2.has-text-centered.has-text-weight-bold -- FICr
				img [
					src="./assets/ficr-logo-24x24.png"
					alt="Federazione Italiana Cronometristi Logo"
				] -->
			div.is-flex.is-align-items-center.timetable-info.px-2.timecontrol-ficrButton.is-unselectable on-click(
				"goToFicrWebsite",
			)
				p.has-text-grey-light.has-text-weight-medium.px-1 -- ficr.it
				img.timetable-info-logo [
					src="./assets/ficr-logo-16x16.png"
					alt="Federazione Italiana Cronometristi Logo"
				]
			<!-- div.is-flex.is-align-items-center.timetable-info.px-2
				p.has-text-grey-light.has-text-weight-medium -- info
				img.timetable-info-logo [
					src="./assets/question_mark_FILL0_wght400_GRAD0_opsz24.svg"
					alt="TimeTable Guide Logo"
				] -->
