p.p-3.has-text-centered.has-text-weight-bold -- Inserisci i tuoi dati
div.control.timetable-forms#timetableSignUp
	
	// Nome
	label.label.is-small.is-uppercase.mb-0.px-3 -- Nome
	input.input.mb-2 [
		type="text"
		placeholder="Nome"
		name="NFCTC-firstname"
		autocomplete="none"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Cognome
	label.label.is-small.is-uppercase.mb-0.px-3 -- Cognome
	input.input.mb-2 [
		type="text"
		placeholder="Cognome"
		name="NFCTC-surname"
		autocomplete="none"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Email
	label.label.is-small.is-uppercase.mb-0.px-3 -- Email
	input.input.mb-2 [
		type="email"
		placeholder="Email"
		name="NFCTC-email"
		autocomplete="none"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Tessera FICr
	label.label.is-small.is-uppercase.mb-0.px-3 -- Tessera FICr
	input.input.mb-2 [
		type="text"
		placeholder="Tessera FICr"
		name="NFCTC-ficrCard"
		autocomplete="none"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Password
	label.label.is-small.is-uppercase.mb-0.px-3 -- Password
	input.input.mb-2 [
		type="password"
		placeholder="Password"
		name="NFCTC-password"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Ripeti Password
	label.label.is-small.is-uppercase.mb-0.px-3 -- Ripeti Password
	input.input.mb-2 [
		type="password"
		placeholder="Ripeti Password"
		name="NFCTC-passwordRepeat"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	// Info passowrd
	div.my-2
		p.has-text-grey-light.is-size-7.px-3
			-- La password deve contenere almeno:
		p.has-text-grey-light.is-size-7.px-3 --  - 8 caratteri
		p.has-text-grey-light.is-size-7.px-3 --  - una lettera maiuscola
		p.has-text-grey-light.is-size-7.px-3 --  - una lettera minuscola
		p.has-text-grey-light.is-size-7.px-3 --  - un numero
		p.has-text-grey-light.is-size-7.px-3
			--  - un carattere speciale (.!?%@$&*)
	
	// Error message
	div.has-text-danger.is-size-7.px-3#signupError
	
	// Registrati
	button.button.is-primary.is-fullwidth.is-rounded.has-text-weight-bold.my-4#timetableSignUpBtn [
		type="button"
		on-click("sendSignUpData")
	]
		-- Registrati

// Back Button
button.button.is-white.is-fullwidth.is-rounded.my-4#timetableSignUpBackBtn [
	type="button"
	on-click("setState", "back")
]
	-- Indietro
