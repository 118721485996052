// Code to get the visitor identifier using FingerprintJS
let FingerprintJS = null;

export async function getBrowserId() {
	// Lazy load the FingerprintJS library
	if (!FingerprintJS) {
		FingerprintJS = await import('https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs@4.2.2/+esm');
	}

	// Get the visitor identifier
	const fp = await FingerprintJS.load();
	const result = await fp.get();

	// Return the visitor identifier
	return result.visitorId;
}