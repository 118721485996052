export default class {
	
	onCreate(input, out) {
		this.env = out.global.env;
		this.state = {
			step: 'welcome',
		};
	}

	setStep(newStep) {
		this.state.step = newStep;
		if (this.env == 'development') { console.log(newStep); }
	}
	
}