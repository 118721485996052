login-header
login-container
	if(state.step == "welcome")
		login-choice [
			on-login("setStep", "login")
			on-register("setStep", "register")
		]
	if(state.step == "login")
		login-signin on-back("setStep", "welcome")
	if(state.step == "register")
		login-signup on-back("setStep", "welcome")
// 900-browserFingerprint
login-footer
