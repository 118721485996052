/* eslint-env browser */

import { buttonError } from '../../lib/buttonUI.js';
import { getBrowserId } from '../../../../lib/browserid.js';
import { resolvePath } from '../../../../lib/pathResolver.js';

export default class {

	// Read global data from fastify
	onCreate(input, out) {
		this.env = out.global.env;
		this.status = 'ready';
	}

	// Emit state
	setState(state) {
		this.emit(state);
	}

	handleKeydown(event) {
		if (event.keyCode === 13) {
			this.sendLoginData();
		}
	}

	// Send data to the server
	async sendLoginData(el) {

		// Remove Text Error
		document.getElementById('loginError').innerText = '';

		// If the button is already loading, return
		if (this.status != 'ready') { return; }

		// Set the status to loading
		this.status = 'loading';

		// Get the DOM element of the signup form
		if (el == undefined) {
			el = { target: HTMLCollection[0] };
			el.target = document.getElementById('timetableLoginBtn');
		}

		// Add is-loading class to the button
		el.target.classList.add('is-loading');

		// Create an empty object to store data
		let data = {};
		let error = false;

		// Get the DOM element of the signup form
		var timetableLoginDOM = document.getElementById('timetableLogin');
		var loginErrorDOM = timetableLoginDOM.querySelector('#loginError');
		const oldText = `${el.target.innerText}`;

		// For each input field
		timetableLoginDOM.querySelectorAll('.input').forEach((input) => {
				
			// If empty, add error class
			if (input.value == '') {
				input.classList.add('is-danger');
				error = true;
				return;
			} else {
				input.classList.remove('is-danger');
			}

			// If type email check valid email
			if (input.type == 'email') {
				var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (!emailRegex.test(input.value)) {
					input.classList.add('is-danger');
					error = true;
					return;
				} else {
					input.classList.remove('is-danger');
				}
			}

			// If type password check more or equal to 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
			if (input.type == 'password') {
				var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*?&])[A-Za-z\d@$.!%*?&]{8,}$/;
				if (!passwordRegex.test(input.value)) {
					input.classList.add('is-danger');
					error = true;
					return;
				} else {
					input.classList.remove('is-danger');
				}
			}

			// Remove NFCTC- from the name of the input field
			var inputName = input.name.replace('NFCTC-', '');
			data[inputName] = input.value;

		});

		if (error) {
			this.status = 'error';
			buttonError(el, null, loginErrorDOM, oldText);
			setTimeout(() => { this.status = 'ready'; }, 3000);
			
			return;
		}

		// Get the browser id
		await getBrowserId().then(visitorId => {
			data.browserid = visitorId;
			if (this.env == 'development') { console.log(visitorId); }
		});

		// Set the address of the api
		var path = resolvePath({ env: this.env, type: 'api', location: window.location, endpoint: 'signin' });

		// Send data via post request to an address like api/signup
		fetch(path, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				if (response.status === 200) {
					if (this.env == 'development') { console.log(response); }
					return response.json();
				} else {
					this.status = 'error';
					console.log(response.status);
					buttonError(el, response.status, loginErrorDOM, oldText);
					setTimeout(() => { this.status = 'ready'; }, 3000);
					throw new Error(`Request failed with status code ${response.status}`);
				}
			})
			.then(data => {
				
				if (this.env == 'development') { console.log(data); }
				
				el.target.classList.remove('is-loading');
				el.target.classList.add('is-success');
				el.target.innerText = 'Accesso completato';

				// Redirect to /dashboard after 1000ms
				setTimeout(() => {
					window.location.assign('/dashboard');
				}, 1000);

			})
			.catch((error) => {
				
				var status = 500;
				console.error('Error:', error);

				// if error contains TypeError: Failed to fetch set status to 503
				if (error.toString().indexOf('Failed to fetch') > -1) {
					status = 503;
				}
				
				buttonError(el, status, loginErrorDOM, oldText);
				setTimeout(() => { this.status = 'ready'; }, 3000);
			});     
	
	}

	// Remove Danger Message and CSS Class
	removeDanger(el) {

		// Remove Danger Message
		// document.getElementById('loginError').innerText = '';

		// remove is-danger class from el focus event
		el.target.classList.remove('is-danger');
	}
	
}