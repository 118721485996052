p.p-3.has-text-centered.has-text-weight-bold -- Accedi
div.control.timetable-forms#timetableLogin
	
	<!-- Nome -->
	label.label.is-small.is-uppercase.mb-0.px-3 -- Nome
	input.input.mb-2 [
		type="email"
		placeholder="Email"
		name="NFCTC-email"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]
	
	<!-- Password -->
	label.label.is-small.is-uppercase.mb-0.px-3 -- Password
	input.input.mb-2 [
		type="password"
		placeholder="Password"
		name="NFCTC-password"
		on-keydown("handleKeydown")
		on-focus("removeDanger")
	]

	<!-- Error Messages -->
	div.has-text-danger.is-size-7.px-3#loginError
	
	<!-- Login -->
	button.button.is-primary.is-fullwidth.is-rounded.has-text-weight-bold.my-4#timetableLoginBtn [
		type="submit"
		on-click("sendLoginData")
		on-submitData("sendLoginData")
	]
		-- Accedi

<!-- Back -->
button.button.is-white.is-fullwidth.is-rounded.my-4#timetableLoginBackBtn [
	type="button"
	on-click("setState", "back")
]
	-- Indietro
