section.section.is-flex.p-4
	div.box.p-0.is-flex-grow-1.is-gapless.is-shadowless.timetable-outer-radius
		div.is-flex.is-justify-content-center.p-0
			p.has-text-centered.has-text-weight-bold.py-3 -- Time Control
			p.has-text-weight-light.has-text-gray.p-3 -- [Beta]
			div.timecontrol-footerBarDivider.my-3
			p.has-text-weight-light.has-text-gray.p-3
				-- v${out.global.appVersion}
				span.timecontrol-loginSmallDevices.is-size-7
					-- -${out.global.buildVersion}
			div.timecontrol-footerBarDivider.my-3.timecontrol-loginSmallDevicesHidden
			p.has-text-weight-light.has-text-gray.p-3.timecontrol-loginSmallDevicesHidden
				-- Build ${out.global.buildVersion}
