// Function to change the button to error state
export function buttonError(el, status, htmlDOM, oldText) {
    el.target.classList.remove('is-loading');
    el.target.classList.add('is-danger');
    el.target.classList.add('is-unselectable');
    el.target.innerHTML = 'Errore';

    switch (status) {

        case 401:
            htmlDOM.innerText = 'Indirizzo email o password errati';
            break;

        case 404:
            htmlDOM.innerText = 'Indirizzo di connessione non trovato';
            break;

        case 409:
            htmlDOM.innerText = 'Indirizzo email già registrato';
            break;

        case 500:
            htmlDOM.innerText = 'Errore interno del server';
            break;

        case 503:
            htmlDOM.innerText = 'Servizio non disponibile';
            break;
    
        default:
            htmlDOM.innerText = 'Correggere i campi errati';
            break;
    }
    
    setTimeout(() => {
        el.target.classList.remove('is-danger');
        el.target.innerHTML = oldText;
    }, 3000);
}